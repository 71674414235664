.header {
    padding: 180px 0 80px 0
}

.right-side {
    margin: auto;
    width: 50%;
}

.right-side h2 {
    color: #fff;
    font-size: 40px;
}

.right-side h3 {
    color: #fff;
    font-size: 22px;
}

.left-side img {
    width: 90%;
    height: auto;
    margin-left: 90px;
}

.type-text {
    color:#0BB5DF;
}
 
@media (max-width: 991px) {
    .right-side h2 {
        font-size: 32px;
    }
    .right-side h3 {
        font-size: 18px;
        line-height: 30px;
    }
    .left-side img {
        margin-left: 45px;
    }
}

@media (max-width: 575px) {
    .header {
        padding: 150px 0 40px 0
    }
    .right-side{
        margin: 0 22px;
    }
    .right-side h2 {
        font-size: 25px;
        margin: 0;
        text-align: center;
    }
    .right-side h3 {
        font-size: 15px;
        line-height: 30px;
    }
    .left-side img {
        width: 80%;
        margin-left: 50px;
    }
}