.main-nav {
    box-shadow: 5px 3px 30px #393939;
}

.main-nav img {
    width: 125px;
}

.nav-hover ul li:hover {
    background-color: #0BB5DF;
    border-radius: 5px;
    transition: 0.4s;
}

.nav-hover ul li {
    font-size: 18px;
    margin: 0 20px;
    padding: 0 18px;
    cursor: pointer;
}

.nav-hover ul li a:hover {
    color: #101820FF!important;
}

.navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}