.about-us {
    padding: 110px 0;
}

.about-left-side img {
    width: 90%;
    height: auto;
    border-radius: 30px;
}

.about-right-side {
    margin: auto;
    width: 50%;
}
.about-right-side h2 {
    color: #fff;
    font-size: 40px;
}

.about-right-side p {
    font-size: 15px;
    line-height: 30px;
}

.hr {
    color: color;
    background-Color: #fff;
    width: 250px;
}

@media (max-width: 991px) {
    .about-right-side h2 {
        font-size: 32px;
    }
    .about-right-side p {
        font-size: 12px;
        line-height: 25px;
    }
    .about-left-side img {
        width: 100%;
        height: auto;
        border-radius: 30px;
    }
}
@media (max-width: 767px){
    .about-right-side h2 {
        margin-top: 20px;
    }
}
@media (max-width: 575px){
    .about-right-side h2 {
       font-size: 25px;
       text-align: center;
       padding: 20px 0
    }
    .about-right-side p {
        text-align: center;
        padding-top: 20px;
     }
    .about-left-side img {
        width: 70%;
        margin-left: 70px;
        border-radius: 30px;
    }
    .hr {
       margin: auto;
    }
}