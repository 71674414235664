.contact-us {
    padding-top: 110px;
    padding-bottom: 50px;
}

.contact-us h2 {
    font-size: 40px;
    color: #fff;
}

.contact-form {
    background-color: transparent!important;
    border: none!important;
    border-bottom: 2px solid #fff!important;
    border-radius: 0!important;
    font-weight: 900!important;
}

.form-control::placeholder {
    color: #fff!important;
}

.form-control:-ms-input-placeholder {
    color: #fff;
}

.form-control::-ms-input-placeholder {
    color: #fff;
}

.btn-b {
    background-color: #101820FF;
    color: #fff;
    border: 1px solid #0BB5DF;
}

.btn-b:hover {
    background-color: #0BB5DF;
    color: #101820FF;
}

@media (max-width: 991px) {
    .contact-us h2 {
        font-size: 32px;
    }
}

@media (max-width: 575px) {
    .contact-us h2 {
        font-size: 25px;
    }
}