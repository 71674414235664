.icon {
  padding-top: 30px;
}

.icon a {
  color: #0BB5DF;
  margin-right: 15px;
}

.icon a:hover {
  color: #fff;
}

.footer p {
  font-size: 12px;
  font-weight: bold;
  padding-top: 30px;
  text-align: end;
}

.footerimg {
  width: 150px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

@media (max-width: 767px) {
  .icon {
    text-align: center;
    padding-bottom: 35px;
  }
  .footer img {
    display: block;
    margin: 0 auto;
    padding-bottom: 20px;
  }
  .footer p {
    text-align: center;
  }
}
